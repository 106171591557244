import List from '@material-ui/core/List'
import classNames from 'classnames'
import map from 'ramda/src/map'
import { memo, useMemo, useState } from 'react'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'
import listModuleConnector from 'root/src/client/logic/api/connectors/listModuleConnector'
import withModuleContext from 'root/src/client/util/withModuleContext'
import GoTopButton from 'root/src/client/web/base/GoTopButton'
import ButtonNew from 'root/src/client/web/base/ButtonNew'
import Button from 'root/src/client/web/static/AccountSettings/Button'
import {
	cardListType,
	payoutListType,
	videoCardListType,
	couponListType,
	projectCommentsListType,
	sidebarDaresListType,
	pendingBrandListType,
	sliderCardsListType,
	tableListType,
	dropsEventListType,
	dropsSliderListType,
	dropEventAccordionListType,
	pointsMarketplaceListType,
	dashboardTableListType,
	campaignClipsListType,
} from 'root/src/shared/constants/moduleTypes'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import dynamic from 'next/dynamic'
import Toggle from 'root/src/client/web/base/Toggle'
import EmbeddedModule from 'root/src/client/web/embedded/EmbeddedModule'
import LoadingButtonNew from 'root/src/client/web/base/LoadingButtonNew'
import MaxWidthContainer from 'root/src/client/web/base/MaxWidthContainer'
import { DynamicImportPlaceholder } from 'root/src/client/web/base/DynamicImportPlaceholder'
import { listStyle } from './style'

const MainHeader = dynamic(() => import('root/src/client/web/typography/MainHeader'))

const listTypeMap = {
	[cardListType]: dynamic(() => import('root/src/client/web/list/listComponents/CardList'), { loading: () => <DynamicImportPlaceholder /> }),
	[payoutListType]: dynamic(() => import('root/src/client/web/list/listComponents/PayoutAdminList'), { loading: () => <DynamicImportPlaceholder /> }),
	[videoCardListType]: dynamic(() => import('root/src/client/web/list/listComponents/VideoCardListModule'), { loading: () => <DynamicImportPlaceholder /> }),
	[couponListType]: dynamic(() => import('root/src/client/web/list/listComponents/CouponAdminList'), { loading: () => <DynamicImportPlaceholder /> }),
	[projectCommentsListType]: dynamic(() => import('root/src/client/web/list/listComponents/CommentsList'), { loading: () => <DynamicImportPlaceholder /> }),
	[sidebarDaresListType]: dynamic(() => import('root/src/client/web/list/listComponents/SidebarList'), { loading: () => <DynamicImportPlaceholder /> }),
	[pendingBrandListType]: dynamic(() => import('root/src/client/web/list/listComponents/PendingBrandUserList'), { loading: () => <DynamicImportPlaceholder /> }),
	[sliderCardsListType]: dynamic(() => import('root/src/client/web/list/listComponents/SliderCardsList'), { loading: () => <DynamicImportPlaceholder /> }),
	[tableListType]: dynamic(() => import('root/src/client/web/list/listComponents/TableList'), { loading: () => <DynamicImportPlaceholder /> }),
	[dropsEventListType]: dynamic(() => import('root/src/client/web/list/listComponents/DropsEventList'), { loading: () => <DynamicImportPlaceholder /> }),
	[dropsSliderListType]: dynamic(() => import('root/src/client/web/list/listComponents/DropsSlider'), { loading: () => <DynamicImportPlaceholder /> }),
	[dropEventAccordionListType]: dynamic(() => import('root/src/client/web/list/listComponents/DropEventAccordionList'), { loading: () => <DynamicImportPlaceholder /> }),
	[pointsMarketplaceListType]: dynamic(() => import('root/src/client/web/list/listComponents/PointsMarketplaceList'), { loading: () => <DynamicImportPlaceholder /> }),
	[dashboardTableListType]: dynamic(() => import('root/src/client/web/list/DashboardTableList'), { loading: () => <DynamicImportPlaceholder /> }),
	[campaignClipsListType]: dynamic(() => import('root/src/client/web/list/listComponents/CampaignClipsList'), { loading: () => <DynamicImportPlaceholder /> }),
}

export const NewDesignHeader = memo(({
	classes, listTitle, listSubtitle, desktopLayoutRow, renderListControls,
}) => (
	<>
		{(listTitle || listSubtitle)
					&& (
						<div className={classNames(
							classes.listModuleNewTitles,
							{ [classes.listModuleTitlesRow]: desktopLayoutRow },
						)}
						>
							{listTitle && (
								<Typography
									variant={typographyVariants.heading1}
									style={{ letterSpacing: '0.02em' }}
								>
									{listTitle}
								</Typography>
							)}
							{listSubtitle
								&& (
									<div
										className={classNames(
											classes.listModuleSubtitle, classes.newSubtitle,
											{ [classes.listModuleSubtitleRow]: desktopLayoutRow },
										)}
									>
										{listSubtitle}
									</div>
								)}
							{desktopLayoutRow && renderListControls}
						</div>
					)
		}
	</>
))
export const OldDesignHeader = memo(({
	classes, listTitle, listSubtitle, desktopLayoutRow, renderListControls,
}) => (
	<>
		{(listTitle || listSubtitle)
					&& (
						<div className={classNames(
							classes.listModuleTitles,
							{ [classes.listModuleTitlesRow]: desktopLayoutRow },
						)}
						>
							{listTitle && <MainHeader inherit>{listTitle}</MainHeader>}
							{listSubtitle
								&& (
									<div className={classNames(
										classes.listModuleSubtitle,
										{ [classes.listModuleSubtitleRow]: desktopLayoutRow },
									)}
									>
										{listSubtitle}
									</div>
								)}
							{desktopLayoutRow && renderListControls}
						</div>
					)
		}
	</>
))

const ListModuleUnconnected = memo(({
	listControls, listTitle, currentRouteId, classes,
	listSubtitle, goTopButton, id, desktopLayoutRow, buttons,
	headerType, listToggleVisibilityProp, listDispatchFn,
	listActionButton, moduleRecordType, embeddedContent,
	...props
}) => {
	const ListComponent = useMemo(() => listTypeMap[props.listType], [listTypeMap, props.listType])
	const { dispatch } = props
	const [checked, setChecked] = useState(false)
	const renderListControls = () => listControls && listControls.length > 0
		&& (
			<div className={classNames(
				classes.buttons,
				{ [classes.buttonsRow]: desktopLayoutRow },
			)}
			>
				{map(({ title, routeId, buttonType, subTitle }) => (
					<ButtonNew
						key={title}
						buttonType={buttonType}
						routeId={routeId}
						isStyled
						disableRipple={buttonType === 'noBackgroundButton'}
					>
						<div>
							<div>{title}</div>
							<span className="button-subtitle">{subTitle}</span>
						</div>
					</ButtonNew>
				),
				listControls)}
			</div>
		)
	return (
		<MaxWidthContainer>
			<List className={classNames(
				classes.listModuleContainer,
				{ [classes.listModuleContainerRow]: desktopLayoutRow },
				'layout-row', `layout-align-start${headerType !== 'newDesign' && '-center'}`,
			)}
			>
				<div style={{ width: '100%' }}>
					<div
						className={classNames(classes.headerWrapper, {
							[classes.backgroundDesign]: headerType === 'backgroundDesign',
						})}
					>
						{listToggleVisibilityProp && (
							<div className={classes.toggleWrapper}>
								<Toggle checked={checked} onChange={() => setChecked(!checked)} />
								<span className={classes.toggleLabel}>
									{checked ? 'Hide Items' : 'Show Items'}
								</span>
							</div>
						)}
						{headerType === 'newDesign' ? (
							<NewDesignHeader
								classes={classes}
								listTitle={listTitle}
								listSubtitle={listSubtitle}
								desktopLayoutRow={desktopLayoutRow}
								renderListControls={renderListControls()}
							/>
						) : (
							<OldDesignHeader
								classes={classes}
								listTitle={listTitle}
								listSubtitle={listSubtitle}
								desktopLayoutRow={desktopLayoutRow}
								renderListControls={renderListControls()}
							/>
						)}
						<div className={classes.tableActions}>
							{embeddedContent && (
								<div className={classes.embeddedContentWrapper}>
									<div>
										<EmbeddedModule {...embeddedContent} />
									</div>
								</div>
							)}
							{listActionButton && (
								<div className={classes.tableActionButtonContainer}>
									<LoadingButtonNew
										onClick={async (e) => {
											e.stopPropagation()
											await listDispatchFn(
												listActionButton.onClickFn(
													moduleRecordType,
													listActionButton.fnInput,
												),
											)
										}}
									>
										{`${listActionButton.label}`}
									</LoadingButtonNew>
								</div>
							)}
						</div>
					</div>
					<ListComponent
						layoutRow={desktopLayoutRow}
						listToggleVisibilityProp={listToggleVisibilityProp}
						checked={checked}
						moduleRecordType={moduleRecordType}
						{...props}
					/>
				</div>
				{goTopButton && <GoTopButton />}
				{!desktopLayoutRow && renderListControls()}
				{map(button => (
					<Button
						routeId={button.routeId}
						key={button.label}
						modalId={button.modalId}
						modalProps={button.modalProps}
						onClick={button.onClick}
						dispatch={dispatch}
					>{button.label}
					</Button>
				), buttons)}
			</List>
		</MaxWidthContainer>
	)
})

export default withModuleContext(
	listModuleConnector(memo(ListModuleUnconnected, comparePropsFn([
		'list', 'currentPage', 'visibleLoadingBlock', 'isMobileRes', 'desktopLayoutRow', 'tableColumns',
	])), listStyle, 'MuiListModule'),
)
